/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

export const PAYPAL_STATE = 'PAYPAL_PAY_STATE';
export const PAYPAL_CONFIG = 'PAYPAL_PAY_CONFIG';
export const PAYPAL_RESET_STATE = 'PAYPAL_PAY_RESET_STATE';

/** @namespace Scandipwa/Store/PayPalPayment/Action/setPayPalState */
export const setPayPalState = (state) => ({
    type: PAYPAL_STATE,
    state,
});

/** @namespace Scandipwa/Store/PayPalPayment/Action/setInitialState */
export const setInitialState = () => ({
    type: PAYPAL_RESET_STATE,
});
